import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LandingPage from "./screens/LandingPage";
import ResetPassword from "./screens/ResetPassword";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      </Routes>
      {/* <div className="App">
        <Navbar />
        <div className="right-side">
          <Routes>
            <Route path="/">
              <Hero />
              <HowItWorks />
              <ContactForm />
            </Route>
            <Route path="/new" />
          </Routes>
        </div>
      </div> */}
    </Router>
  );
};

export default App;
