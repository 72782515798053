import React, { useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "../styles/ResetPassword.css";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"success" | "error" | "">("");
  const [loading, setLoading] = useState(false);

  // Extract email & OTP from URL query params
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const otp = searchParams.get("otp") || "";
  // 🔒 Password Validation Rules
  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!?_-])[A-Za-z\d@#$%^&+=!?_-]{8,}$/;
    return passwordRegex.test(password)
      ? ""
      : "Password must be at least 8 characters, include a capital letter, at least one number, and a special character '@#$%^&+=!?_-'.";
  };

  // 🔒 Handle Password Change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(validatePassword(e.target.value));
  };

  // 🔒 Handle Confirm Password Change
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(
      e.target.value !== password ? "Passwords do not match!" : ""
    );
  };

  // 👀 Toggle Password Visibility
  const togglePasswordVisibility = (id: string) => {
    const input = document.getElementById(id) as HTMLInputElement;
    input.type = input.type === "password" ? "text" : "password";
  };

  // 🔄 Handle Password Reset Submission
  const handleResetPassword = async () => {
    if (passwordError || confirmPasswordError || !password || !confirmPassword)
      return;

    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.ollo-health.com/api/password/update-password",
        {
          email,
          otp,
          password,
          confirmPassword,
        }
      );

      if (response.data.success) {
        setAlertMessage(response.data.message);
        setAlertType("success");
      } else {
        setAlertMessage(response.data.message);
        setAlertType("error");
      }
    } catch (error) {
      setAlertMessage("Something went wrong. Please try again.");
      setAlertType("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-container">
      <div className="reset-box">
        <h3 className="reset-title">Ollo Health</h3>

        {alertMessage && (
          <div
            className={`alert ${alertType === "success" ? "success" : "error"}`}
          >
            {alertMessage}
          </div>
        )}

        <h4 className="reset-subtitle">Reset Password</h4>

        <div className="input-group">
          <label htmlFor="password">New Password:</label>
          <div className="input-container">
            <input
              type="password"
              id="password"
              className={passwordError ? "input-error" : ""}
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <span
              className="eye-icon"
              onClick={() => togglePasswordVisibility("password")}
            >
              👁
            </span>
          </div>
          {passwordError && (
            <small className="error-text">{passwordError}</small>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <div className="input-container">
            <input
              type="password"
              id="confirmPassword"
              className={confirmPasswordError ? "input-error" : ""}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            <span
              className="eye-icon"
              onClick={() => togglePasswordVisibility("confirmPassword")}
            >
              👁
            </span>
          </div>
          {confirmPasswordError && (
            <small className="error-text">{confirmPasswordError}</small>
          )}
        </div>

        <button
          className="reset-button"
          onClick={handleResetPassword}
          disabled={loading}
        >
          {loading ? "Resetting..." : "Reset Password"}
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
