import React, { useEffect, useState } from "react";
import "../styles/Navbar.css";

const Navbar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  
  // Handle scroll event to change navbar style on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Scroll to section function
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <div className="navbar-logo" onClick={() => scrollToSection('hero')}>
        <img src="/Ollo.svg" alt="Ollo Logo" width={60} height={60}  />
        </div>
        
        <div className="navbar-links">
          <button 
            className="nav-link" 
            onClick={() => scrollToSection('features')}
          >
            Features
          </button>
          
          <button 
            className="nav-link" 
            onClick={() => scrollToSection('how-it-works')}
          >
            How it Works
          </button>
          
          <button 
            className="nav-link" 
            onClick={() => scrollToSection('contact')}
          >
            Get in Touch
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
