import React, { useState } from "react";
import "../styles/ContactForm.css";

const ContactForm: React.FC = () => {
  const [emailFrom, setEmailFrom] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://81gud6s5r7.execute-api.us-east-1.amazonaws.com/prod/send_email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toEmail: "info@ollo-health.com",
            fromEmail: "info@ollo-health.com",
            subject: "New Inquiry",
            messageBody: `You have a new inquiry from:
            Email: ${emailFrom}
            Name: ${name}`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      // If the email is sent successfully, show a confirmation message and reset the input
      setMessage("Thank you! Your message has been sent successfully.");
      setEmailFrom("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error. Please try again.");
    }

    // Clear the message after a few seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <section className="contact-section" id="contact">
      <div className="contact-container">
        <div className="contact-form-container">
          <h2 className="contact-title">Let's get in touch!</h2>
          <p className="contact-description">
            Have questions about how Ollo can transform your health journey? Our
            team is here to help. Reach out for personalized support
            and information.
          </p>

          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Full Name"
                className="form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                className="form-input"
                value={emailFrom}
                onChange={(e) => setEmailFrom(e.target.value)}
                required
              />
            </div>
            
            <div className="form-group">
            <button type="submit" className="submit-button">
              Submit
            </button>
            </div>
            
            {message && (
              <div className={`message-alert ${message.includes("error") ? "error" : "success"}`}>
                {message}
              </div>
            )}
          </form>
          
          <div className="contact-details">
            <p className="contact-info">
              📧 Info@ollo-health.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;