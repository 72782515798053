import React from 'react';
import '../styles/HowItWorksNew.css';

const HowItWorksNew = () => {
  const steps = [
    {
      number: 1,
      title: "Book Your Annual",
      description: "Schedule your no-cost preventive visit with a local provider through Ollo."
    },
    {
      number: 2,
      title: "Get Your Wellness Plan",
      description: "Receive personalized insights and recommendations based on your labs and goals."
    },
    {
      number: 3,
      title: "Stay on Track with AI",
      description: "Monitor your progress and let AI adjust your plan for optimal results."
    }
  ];

  return (
    <div className="how-it-works-container" id="how-it-works">
      <h2 className="how-it-works-title">How Ollo Works</h2>
      
      <div className="steps-container">
        {steps.map((step) => (
          <div className="step-card" key={step.number}>
            <div className="step-number">{step.number}</div>
            <h3 className="step-title">{step.title}</h3>
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorksNew;