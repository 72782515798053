import React, { useState } from 'react';
import '../styles/NewHero.css';

const NewHero = () => {
  const [email, setEmail] = useState('');
    const [emailFrom, setEmailFrom] = useState("");
    const [message, setMessage] = useState("");
  
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://81gud6s5r7.execute-api.us-east-1.amazonaws.com/prod/send_email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toEmail: "info@ollo-health.com",
            fromEmail: "info@ollo-health.com",
            subject: "New Waitlisted User",
            messageBody: `You have a new waitlisted user: ${emailFrom}`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      // If the email is sent successfully, show a confirmation message and reset the input
      setMessage("You have been added to the waitlist.");
      setEmailFrom(""); // Reset the input field
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error. Please try again.");
    }

    // Clear the message after a few seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };
  
  return (
    <div className="hero-container" id='hero'>
      <div className="hero-content">
        <h1 className="hero-title">Your Personal AI Health & Nutrition Assistant</h1>
        <p className="hero-description">
          Take control of your long-term health — starting with your annual physical.
          We connect you with a primary care provider, walk you through recommended preventive tests, and then deliver AI-powered insights, nutrition plans, and coaching based on your results.
        </p>
        <div className="waitlist-form">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <input 
                type="email" 
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email" 
                required
                className="email-input"
              />
              <button type="submit" className="hero-button primary">Join Waitlist</button>
            </div>
          </form>
        </div>
      </div>
      
      <div className="app-preview">
        <div className="app-window">
          <div className="app-content">
            <img 
              src="../screenshots.png" 
              alt="App interface screenshot" 
              className="app-screenshot" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHero;