import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import ContactForm from "../components/ContactForm";
import "../App.css";
import NewHero from "../components/NewHero";
import Features from "../components/Features";
import HowItWorksNew from "../components/HowItWorksNew";

const LandingPage = () => {
  return (
    <div className="App">
      <Navbar />
      <NewHero/>
      <Features />
      <HowItWorksNew />
      {/* <Hero /> */}
      {/* <HowItWorks /> */}
      <ContactForm />
    </div>
  );
};

export default LandingPage;
