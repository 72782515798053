import React from 'react';
import '../styles/Features.css';

const Features = () => {
  const featuresData = [
    {
      icon: "🩺",
      title: "Annual Health Screening & Onboarding",
      description: "We guide you through your annual physical, help you understand which tests are recommended, and connect you with a local provider — all before building your personalized plan."
    },
    {
      icon: "🌿",
      title: "Personalized Wellness Guidance",
      description: "Actionable lifestyle and nutrition recommendations generated after your annual visit — continuously updated by our AI to support long-term health"
    },
    {
      icon: "🍽️",
      title: "Personalized Diet Plans",
      description: "AI-generated meal plans tailored to your health goals, dietary restrictions, and preferences."
    },
    {
      icon: "📊",
      title: "Macro & Micro Tracking",
      description: "Advanced tracking of calories, macronutrients, and micronutrients with detailed analytics."
    },
    {
      icon: "🛒",
      title: "Smart Grocery Lists",
      description: "Automated shopping lists based on your meal plans with direct grocery delivery integration."
    },
    {
      icon: "📅",
      title: "Meal Planning",
      description: "Weekly meal schedules with recipe suggestions and preparation instructions."
    },

  ];

  return (
    <div className="features-container" id="features">
      <h2 className="features-title">Comprehensive Health & Nutrition Management</h2>
      
      <div className="features-grid">
        {featuresData.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-icon-container">
              <span className="feature-icon">{feature.icon}</span>
            </div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;